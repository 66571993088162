import React from "react"
import { Helmet } from "react-helmet"
import { mainUrl } from "../../../js/config"

export default function SEO({ title, description, canonical, ogImage }) {
  const defaultMetaTags = {
    title: "Professional Full-Cycle Web &amp; Software Development — OS-System",
    description:
      "OS-System provides full-cycle development from initial concept to a live application. We design, build and deploy innovative software and modern web applications.",
    canonical: `${mainUrl}`,
    ogImage: `${mainUrl}/sharing.png`,
  }

  const seo = {
    title: title || defaultMetaTags.title,
    description: description || defaultMetaTags.description,
    canonical: canonical || defaultMetaTags.canonical,
    ogImage: ogImage || defaultMetaTags.ogImage,
  }

  return (
    <Helmet defer={false}>
      <html lang="en" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={seo.canonical} />
      <meta property="description" content={seo.description} />
      <meta itemprop="name" content={seo.title} />
      <meta itemprop="description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.canonical} />
      <meta name="twitter:image" content={seo.ogImage} />
      <meta name="twitter:image:src" content={seo.ogImage} />
      <meta
        name="twitter:image:alt"
        content="OS-System - IT outsourcing company which builds your ideas"
      />
      <meta property="og:url" content={seo.canonical} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.ogImage} />
      <meta property="og:image:secure_url" content={`${mainUrl}/sharing.png`} />
      <meta property="og:image:alt" content="Development team OS-System" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="968" />
      <meta property="og:image:height" content="504" />
    </Helmet>
  )
}
